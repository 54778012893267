import React from "react";
import { Helmet } from "react-helmet";

import Header from "components/Header";
import Footer from "components/Footer";
import Contact from "components/Contact";

function Layout({ children }) {
    return (
        <main>
            <Helmet>
                <link rel="stylesheet" href="https://use.typekit.net/tvc4sxb.css"/>
            </Helmet>

            <Header/>
            {children}

            <Contact/>
            <Footer/>
        </main>
    );
} 

export default Layout;
