import { Link } from "gatsby";
import React from "react";

import { button } from "./button.module.css"

function Button({ children, inverted=false, ...props }) {
    let colorStyle = inverted ? { color: `var(--black)`, background: `var(--white)`} : null

    return (
        <Link {...props}>
            <button style={colorStyle} className={ button }>{ children }</button>
        </Link>
    )
}

export default Button