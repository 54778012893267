import React from 'react'
import Heading from 'components/Heading'

import { contact, contactSection, socialLinks, socialLink, socialIcon, resinText } from './contact.module.css'

function Contact() {
    return (
        <section className={contactSection}>
            <div className={contact}>
                <Heading level="h3">Ring This Number If You Want To Stick</Heading>
                <p>It's a mobile number because we haven't got a landline:</p>
                <Heading level="h4">07540 698 965</Heading>
                <p>It's an email because we like emails too:</p>
                <Heading level="h4">chat@resin.marketing</Heading>
                <p>Or you can connect if you fancy stalking us out.</p>

                <div className={socialLinks}>
                    <div className={socialLink}>
                        <svg className={socialIcon} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.31118 0C1.94037 0 0 1.94032 0 4.31118V17.6895C0 20.0603 1.94032 22 4.31118 22H17.6895C20.0604 22 22 20.0604 22 17.6895V4.31118C22 1.94037 20.0604 0 17.6895 0H4.31118ZM5.3955 3.63044C6.53226 3.63044 7.23245 4.3767 7.25406 5.35765C7.25406 6.31694 6.53221 7.08417 5.37352 7.08417H5.35219C4.23707 7.08417 3.51632 6.31698 3.51632 5.35765C3.51632 4.37672 4.25888 3.63044 5.39548 3.63044H5.3955ZM15.1915 8.21527C17.3777 8.21527 19.0166 9.64417 19.0166 12.7148V18.4472H15.6942V13.0992C15.6942 11.7552 15.2133 10.8384 14.011 10.8384C13.0931 10.8384 12.546 11.4564 12.3058 12.0533C12.218 12.2669 12.1964 12.5652 12.1964 12.864V18.4472H8.87401C8.87401 18.4472 8.91761 9.38776 8.87401 8.44971H12.1971V9.86546C12.6386 9.18426 13.4284 8.21524 15.1915 8.21524V8.21527ZM3.7123 8.45044H7.03471V18.4473H3.7123V8.45044Z" fill="var(--blue)"/>
                            <defs>
                            <linearGradient id="paint0_linear" x1="11" y1="0" x2="11" y2="22" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#3B5996"/>
                            <stop offset="1" stopColor="#27398D"/>
                            </linearGradient>
                            </defs>
                        </svg>

                        <Heading level="h4">LinkedIn</Heading>
                    </div>
                    
                    <div className={socialLink}>
                        <svg className={socialIcon} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.6429 0H2.35714C1.0558 0 0 1.0558 0 2.35714V19.6429C0 20.9442 1.0558 22 2.35714 22H19.6429C20.9442 22 22 20.9442 22 19.6429V2.35714C22 1.0558 20.9442 0 19.6429 0ZM17.2415 7.79821C17.2513 7.93571 17.2513 8.07812 17.2513 8.21562C17.2513 12.4732 14.0103 17.379 8.08795 17.379C6.26116 17.379 4.56696 16.8487 3.14286 15.9353C3.40313 15.9647 3.65357 15.9746 3.91875 15.9746C5.42634 15.9746 6.81116 15.4638 7.91607 14.5996C6.50179 14.5701 5.31339 13.642 4.9058 12.3652C5.40179 12.4388 5.84866 12.4388 6.35938 12.3062C4.88616 12.0067 3.78125 10.7103 3.78125 9.14375V9.10446C4.20848 9.34509 4.70937 9.49241 5.23482 9.51205C4.79307 9.21821 4.43093 8.81958 4.18072 8.35173C3.93052 7.88388 3.80002 7.36136 3.80089 6.8308C3.80089 6.2317 3.95804 5.6817 4.23795 5.20536C5.82411 7.15982 8.2058 8.43661 10.8772 8.57411C10.4205 6.38884 12.0558 4.61607 14.0201 4.61607C14.9482 4.61607 15.783 5.00402 16.3723 5.63259C17.0991 5.49509 17.7964 5.225 18.4152 4.8567C18.1746 5.60312 17.6688 6.2317 17.0009 6.62946C17.6491 6.56071 18.2777 6.37902 18.8571 6.12857C18.4201 6.77187 17.8701 7.34152 17.2415 7.79821Z" fill="var(--blue)"/>
                            <defs>
                            <linearGradient id="paint0_linear" x1="11" y1="0" x2="11" y2="22" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#3B5996"/>
                            <stop offset="1" stopColor="#27398D"/>
                            </linearGradient>
                            </defs>
                        </svg>

                        <Heading level="h4">Twitter</Heading>
                    </div>

                    <div className={socialLink}>
                        <svg className={socialIcon} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.01707 0C1.79179 0 0 1.79179 0 4.01706V17.9829C0 20.2082 1.79179 22 4.01707 22H11.5865V13.3994H9.3122V10.3029H11.5865V7.65737C11.5865 5.57892 12.9302 3.67056 16.0256 3.67056C17.2789 3.67056 18.2057 3.79088 18.2057 3.79088L18.1328 6.68251C18.1328 6.68251 17.1876 6.67358 16.1562 6.67358C15.04 6.67358 14.861 7.18789 14.861 8.04171V10.3029H18.2215L18.0751 13.3994H14.861V22H17.9829C20.2082 22 22 20.2082 22 17.9829V4.01708C22 1.79181 20.2082 2.2e-05 17.9829 2.2e-05H4.01705L4.01707 0Z" fill="var(--blue)"/>
                            <defs>
                            <linearGradient id="paint0_linear" x1="11" y1="0" x2="11" y2="22" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#3B5996"/>
                            <stop offset="1" stopColor="#27398D"/>
                            </linearGradient>
                            </defs>
                        </svg>

                        <Heading level="h4">Facebook</Heading>
                    </div>

                </div>
            </div>
            

            <svg className={resinText} width="1920" height="297" viewBox="0 0 1920 297" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.37733 148.995C1.37733 99.3298 1.37733 55.1832 0 12.4162H136.356C137.733 23.4529 140.488 73.1178 140.488 100.709C162.525 42.767 216.241 1.37958 301.636 0V132.44C201.09 129.681 140.488 157.272 140.488 295.23V514.584H1.37733V148.995ZM437.991 293.851C437.991 357.312 469.67 418.013 538.537 418.013C596.385 418.013 612.913 394.56 626.686 364.209H765.796C747.891 426.291 692.798 527 535.782 527C370.502 527 298.881 402.838 298.881 270.398C298.881 113.126 380.143 0 541.291 0C713.458 0 772.683 125.542 772.683 251.084C772.683 267.639 772.683 278.675 771.306 293.851H437.991ZM632.195 208.317C630.818 150.374 607.403 100.709 539.914 100.709C473.802 100.709 446.255 146.236 440.746 208.317H632.195ZM921.435 364.209C932.453 404.217 964.132 426.291 1016.47 426.291C1067.43 426.291 1086.71 408.356 1086.71 378.005C1086.71 346.275 1064.68 332.479 993.056 315.924C829.154 274.537 802.984 223.492 802.984 153.133C802.984 78.6361 855.323 0 1006.83 0C1159.71 0 1210.67 82.7749 1214.81 151.754H1082.58C1078.45 133.819 1066.05 97.9503 999.943 97.9503C954.491 97.9503 939.34 118.644 939.34 140.717C939.34 165.55 954.491 177.966 1037.13 197.28C1205.17 235.908 1228.58 293.851 1228.58 368.348C1228.58 451.123 1165.22 527 1012.34 527C858.078 527 796.098 453.882 785.079 364.209H921.435ZM1271.28 12.4162H1410.39V515.963H1271.28V12.4162ZM1468.24 139.338C1468.24 96.5707 1468.24 49.6649 1466.86 12.4162H1601.84C1604.59 24.8324 1605.97 60.7016 1607.35 75.877C1623.87 44.1466 1665.19 0 1753.34 0C1853.89 0 1920 68.9791 1920 194.521V514.584H1780.89V211.076C1780.89 154.513 1761.61 113.126 1701 113.126C1641.78 113.126 1608.72 146.236 1608.72 234.529V514.584H1469.61V139.338H1468.24Z" fill="#F5F5F5"/>
            </svg>


        </section>
    );
}

export default Contact