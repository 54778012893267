import React from "react";

const validHeadingLevels = ["h1", "h2", "h3", "h4", "h5", "h6"];
const validColours = ["red", "white", "grey"];
const validFontSize = ["largest", "large", "medium", "default"];
const sanitize = prop => (prop ? prop.toLowerCase() : "");
const selectValid = (prop, validArr, fallBack) =>
    validArr.includes(prop) ? prop : fallBack;

function Heading({
    className,
    children,
    level,
    colour,
    fontSize,
    noBg,
    ...props
}) {
    const SelectedHeading = selectValid(
        sanitize(level),
        validHeadingLevels,
        "p"
    );
    const SelectedColour = selectValid(sanitize(colour), validColours, "red");
    const SelectedFontSize = selectValid(
        sanitize(fontSize),
        validFontSize,
        "default"
    );

    let classes = [
        ...(className ? [className] : []),
        `Heading`,
        `Heading__${SelectedColour}`,
        `Heading__${SelectedFontSize}`,
        ...(noBg ? ["Heading__noBg"] : []),
    ];

    return (
        <SelectedHeading className={classes.join(" ")} {...props}>
            {children}
        </SelectedHeading>
    );
}

Heading.defaultProps = {
    colour: `red`,
    className: ``,
    level: ``,
    fontSize: `default`,
    noBg: false,
};

export default Heading;
