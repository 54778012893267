import React from "react";
import { Link } from 'gatsby'

import { leftContainer } from "./footer.module.css"

function Footer() {
    return (
        <footer> 
            <div className={leftContainer}>
                <Link to="/privacy-policy"><p>Privacy &#38; Cookie Policy</p></Link>
                <Link to="/terms-conditions"><p>Terms &#38; Conditions</p></Link>
            </div>

            <div>
                <p>© Copyright 2021 Resin Marketing. All Rights Reserved.</p>
            </div>
        </footer>
    );
}

export default Footer